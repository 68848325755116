import { ref, onMounted, nextTick } from "vue";
import { message } from "ant-design-vue";
import { page, saveCategory, saveOrUpdate } from "@/api/category";
import { page as menuPage } from '@/api/menu';

export default function usePlanetManage() {
  const source = ref([]);
  const level = ref(1);
  const columns = [
    {
      title: "ID",
      dataIndex: "logicId",
      // width: "25%",
      scopedSlots: { customRender: "logicId" }
    },
    {
      title: "标题",
      dataIndex: "name",
      // width: "15%",
      scopedSlots: { customRender: "name" }
    },
    {
      title: "操作",
      dataIndex: "operation",
      // width: "40%",
      slots: { customRender: "operation" }
    }
  ];
  const pagination = ref({
    position: "bottom",
    total: 200,
    defaultPageSize: 20,
    pageSizeOptions: ["10", "15", "20", "30"],
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: total => `共 ${total} 条`,
    hideOnSinglePage: true
  });
  const getList = async (pageNo = 1, pageSize = 20, level = 1) => {
    // eslint-disable-next-line no-unused-vars
    const { data, msg, status } = await page({
      pageNo,
      pageSize,
      level
    });
    console.log(data);
    if (status === "200") {
      const { dataOnThisPage, total } = data;
      source.value = dataOnThisPage;
      pagination.value = { ...pagination.value, total: total };
    } else {
      message.error(msg);
    }
  };
  const handlerTableChange = () => {
    const { current, pageSize } = pagination;
    getList(current, pageSize, level.value);
  };
  onMounted(getList);

  const menuList = ref([])
  const getMenuList = async () => {
    const { data, status } = await menuPage({
      pageNo: 1, pageSize: 999
    })
    if (status === '200') {
      menuList.value = data.dataOnThisPage || []
    }
  }
  onMounted(getMenuList);

  // modal
  const modTitle = ref("新建一级类目");
  const visible = ref(false);
  const confirmLoading = ref(false);

  const addNewCate = () => {
    resetForm();
    modTitle.value = "新建一级类目";
    visible.value = true;
  };
  const handleOk = async () => {
    formRef.value.validate().then(async val => {
      confirmLoading.value = true;
      const params = { ...form.value, level: level.value };

      const { msg, status } = await saveOrUpdate(params);
      if (status === "200") {
        message.success(msg);
        confirmLoading.value = false;
        visible.value = false;
        getList();
      } else {
        message.error(msg);
      }
    });
  };
  const handlerCancel = () => {
    formRef.value.resetFields();
    nextTick(() => {
      confirmLoading.value = false;
      visible.value = false;
      resetForm();
    });
  };
  // form
  const formRef = ref(null);
  const form = ref({
    logicId: "",
    menuId: '',
    name: ""
  });
  const rules = ref({
    logicId: [{ required: true, message: "必填项", trigger: "blur" }],
    name: [{ required: true, message: "请输入一级类目名称", trigger: "blur" }]
  });
  const resetForm = () => {
    form.value = {
      logicId: "",
      menuId: '',
      name: ""
    };
  };
  const editCates = row => {
    modTitle.value = "修改一级类目";
    visible.value = true;
    const { logicId, name, menuId } = row;
    form.value = { logicId, name, level: level.value, menuId };
    console.log(form.value);
  };
  return {
    columns,
    source,
    pagination,
    modTitle,
    visible,
    confirmLoading,
    rules,
    formRef,
    form,
    level,
    addNewCate,
    editCates,
    handlerTableChange,
    getList,
    handleOk,
    handlerCancel,
    resetForm,

    menuList
  };
}
