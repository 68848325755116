<template>
  <a-button style="margin-bottom:10px;" type="primary" @click="addNewCate">新建一级类目</a-button>
  <a-table
    bordered
    rowKey="id"
    size="middle"
    :columns="columns"
    :data-source="source"
    :pagination="pagination"
    @change="handlerTableChange"
  >
    <template #operation="{record}">
      <a-button type="primary" size="small" @click="editCates(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal
    :title="modTitle"
    v-model:visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-item label="类目名称" name="name">
        <a-input v-model:value="form.name" />
      </a-form-item>
      <a-form-item label="关联菜单" name="star">
        <a-select v-model:value="form.menuId">
          <a-select-option
            v-for="option of menuList"
            :key="option.logicId"
            :value="option.logicId"
          >{{option.name}}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import useCategoryManage from "@/js/useCategoryManage";
export default {
  setup() {
    const {
      columns,
      source,
      pagination,
      modTitle,
      visible,
      confirmLoading,
      rules,
      formRef,
      form,
      addNewCate,
      editCates,
      handlerTableChange,
      getList,
      getStars,
      handleOk,
      handlerCancel,
      resetForm,
      starList,
      handleMenuClick,
      menuList
    } = useCategoryManage();
    return {
      columns,
      source,
      starList,
      pagination,
      modTitle,
      visible,
      confirmLoading,
      rules,
      formRef,
      form,
      addNewCate,
      editCates,
      handlerTableChange,
      getList,
      getStars,
      handleOk,
      handlerCancel,
      resetForm,
      handleMenuClick,
      menuList
    };
  }
};
</script>
